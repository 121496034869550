import { getCoreRowModel, useReactTable } from "@tanstack/react-table";

import { useCollaborators } from "@/hooks";
import { useNavigateModal } from "@/router/useNavigateModal";
import { useUserStore } from "@/stores";
import { CollaboratorType } from "@/types";
import { Table } from "@/ui";
import { getTableColumns } from "./TeachersTableColumns";

interface MeditationsTableProps {
  searchQuery: string;
}

export const TeachersTable = ({ searchQuery }: MeditationsTableProps) => {
  const navigateModal = useNavigateModal();
  const { useAllCollaborators } = useCollaborators();
  const { data: collaborators, isLoading } = useAllCollaborators({
    "filter[name]": searchQuery,
    "filter[type]": CollaboratorType.Teacher,
  });
  const user = useUserStore((state) => state.user);
  const table = useReactTable({
    data: collaborators?.data ?? [],
    columns: getTableColumns(navigateModal, user),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <Table table={table} isLoading={isLoading} />
    </div>
  );
};
