import type {
  AddEditCourseModule,
  Batch,
  Course,
  EditPartner,
  Partner,
  User,
} from "@/types";
import type { ServiceResponse } from "./api.types";
import { privateAPI } from "./axios";

export interface Module {
  id: string;
  title: string;
  description?: string;
  video?: string;
  duration: string;
  partner_id: string;
}

export interface CourseSession {
  id: string;
  name: string;
  modules: Module[];
}

export const getPartners = async (
  page: number,
  searchQuery = "",
): Promise<ServiceResponse<Partner[]>> => {
  const query = searchQuery ? `filter[search]=${searchQuery}` : "";
  const response = await privateAPI.get<ServiceResponse<Partner[]>>(
    `/partners?page=${page}&${query}`,
  );
  return response.data;
};

export const getPartner = async (
  partnerId: Partner["id"] | undefined,
): Promise<Partner> => {
  const response = await privateAPI.get<ServiceResponse<Partner>>(
    `/partners/${partnerId}`,
  );
  return response.data.data;
};

export const getManagedPartner = async (
  userId: User["id"] | undefined,
): Promise<Partner> => {
  const response = await privateAPI.get<ServiceResponse<Partner>>(
    `users/${userId}/partners/managed`,
  );
  return response.data.data;
};

export const addPartner = async (partner: Partner): Promise<Partner> => {
  const response = await privateAPI.post<ServiceResponse<Partner>>(
    `/partners`,
    partner,
  );

  return response.data.data;
};

export const updatePartner = async ({
  partnerId,
  partner,
}: {
  partnerId: Partner["id"];
  partner: EditPartner;
}): Promise<Partner> => {
  const response = await privateAPI.put<ServiceResponse<Partner>>(
    `/partners/${partnerId}`,
    {
      ...partner,
    },
  );
  return response.data.data;
};

export const getBatches = async (
  partnerId: Partner["id"],
  page: number,
): Promise<ServiceResponse<Batch[]>> => {
  const params: Record<string, string | number> = {
    include: "createdBy",
    page,
  };

  const response = await privateAPI.get<ServiceResponse<Batch[]>>(
    `/partners/${partnerId}/batches`,
    {
      params,
    },
  );

  return response.data;
};

export const getSelfPacedCourse = async (
  partnerId: Partner["id"] | undefined,
): Promise<Course> => {
  const params: Record<string, string | number> = {
    include: "collaborators",
  };
  const response = await privateAPI.get<ServiceResponse<Course>>(
    `/partners/${partnerId}/self-paced-course`,
    { params },
  );
  return response.data.data;
};

export const getSelfPacedCourseModules = async (
  partnerId: Partner["id"] | undefined,
): Promise<CourseSession[]> => {
  const response = await privateAPI.get<ServiceResponse<CourseSession[]>>(
    `/partners/${partnerId}/self-paced-course/modules`,
  );
  return response.data.data;
};

export const updateCourseModule = async ({
  moduleId,
  module,
}: {
  moduleId: Module["id"];
  module: AddEditCourseModule;
}) => {
  await privateAPI.put(`/modules/${moduleId}`, {
    ...module,
  });
};

export const deleteCourseModuleVideo = async (moduleId: Module["id"]) => {
  await privateAPI.delete(`/modules/${moduleId}/video`);
};
