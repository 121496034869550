import { PencilSimple, QuestionMark } from "@phosphor-icons/react";
import { createColumnHelper } from "@tanstack/react-table";

import i18n from "@/i18n";
import { MODAL_ROUTES } from "@/router";
import type { useNavigateModal } from "@/router/useNavigateModal";
import type { Collaborator, User } from "@/types";
import { Button } from "@/ui";
import { getIsDefaultCollaborator } from "@/utils";

const columnHelper = createColumnHelper<Collaborator>();

export function getTableColumns(
  navigateModal: ReturnType<typeof useNavigateModal>,
  user: User | null,
) {
  const columns = [
    columnHelper.accessor("name", {
      header: "Teacher name",
      cell: (info) => {
        const collaborator = info.row.original;
        return (
          <div className="flex items-center space-x-2">
            {collaborator?.avatar ? (
              <img
                className="size-9 rounded-full bg-contain"
                src={collaborator.avatar}
                alt={i18n.t("collaborators.collaborator_name")}
              />
            ) : (
              <div className="flex h-9 w-9 items-center justify-center rounded-full border border-neutral-50 bg-white">
                <QuestionMark size={24} className="text-primary-600" />
              </div>
            )}
            <span>{collaborator.name} </span>
          </div>
        );
      },
    }),
    columnHelper.accessor("title", {
      header: i18n.t("collaborators.organization"),
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("activeMeditationsCount", {
      header: i18n.t("collaborators.number_of_active_meditations"),
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "actions",
      cell: (info) => {
        return (
          <Button
            variant="transparent"
            onClick={() => {
              const isDefaultCollaborator = getIsDefaultCollaborator(
                info.row.original.name,
              );

              if (!isDefaultCollaborator || user?.role == "admin") {
                navigateModal(MODAL_ROUTES.editCollaborator, {
                  collaborator: info.row.original,
                });
              }
            }}
          >
            <PencilSimple size={24} />
          </Button>
        );
      },
    }),
  ];

  return columns;
}
