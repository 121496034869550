import { LockSimple, LockSimpleOpen } from "@phosphor-icons/react";
import { createColumnHelper } from "@tanstack/react-table";

import i18n from "@/i18n";
import { MODAL_ROUTES } from "@/router";
import type { useNavigateModal } from "@/router/useNavigateModal";
import { mapAccessTypeToColor } from "@/types";
import type { User } from "@/types";
import { Button, Circle } from "@/ui";
import Badge from "@/ui/Badge";
import {
  capitalizeFirstLetter,
  formatArrayToString,
  formatDateToDDMMYYYY,
  tw,
} from "@/utils";

const columnHelper = createColumnHelper<User>();

export function getTableColumns(
  navigateModal: ReturnType<typeof useNavigateModal>,
) {
  const columns = [
    columnHelper.accessor("email", {
      header: i18n.t("users.user_email"),
      cell: (info) => (
        <span className={tw(info.row.original.locked && "opacity-50")}>
          {info.getValue()}{" "}
        </span>
      ),
    }),
    columnHelper.accessor("memberships", {
      header: i18n.t("users.memberships"),
      cell: (info) => {
        const memberships = formatArrayToString(info.getValue());
        return (
          <span className={tw(info.row.original.locked && "opacity-50")}>
            {memberships === "" ? "no memberships" : memberships}{" "}
          </span>
        );
      },
    }),
    columnHelper.accessor("user_type", {
      header: i18n.t("users.user_type"),
      cell: (info) => (
        <span className={tw(info.row.original.locked && "opacity-50")}>
          {info.getValue() ? capitalizeFirstLetter(info.getValue()) : "Regular"}{" "}
        </span>
      ),
    }),
    columnHelper.accessor("access_type", {
      header: i18n.t("users.access_type"),
      cell: (info) => (
        <Badge
          className={tw(info.row.original.locked && "opacity-50")}
          text={info.getValue()}
          color={mapAccessTypeToColor(info.getValue())}
        />
      ),
    }),
    columnHelper.accessor("email_verified_at", {
      header: i18n.t("users.verified_date"),
      cell: (info) => <span>{formatDateToDDMMYYYY(info.getValue())} </span>,
    }),
    columnHelper.display({
      id: "manageAccess",
      header: i18n.t("users.access_levels"),
      cell: (info) => {
        const user = info.row.original;
        const isLocked = user.locked;
        return (
          <Button
            onClick={() =>
              navigateModal(MODAL_ROUTES.manageUserAccessLevel, { user })
            }
            variant="outlined"
            disabled={isLocked}
          >
            {i18n.t("users.manage_access")}
          </Button>
        );
      },
    }),
    columnHelper.display({
      id: "manageLocked",
      header: "",
      cell: (info) => {
        const user = info.row.original;
        return (
          <Button
            onClick={() => navigateModal(MODAL_ROUTES.lockUser, { user })}
            variant="transparent"
          >
            {info.row.original.locked ? (
              <Circle className="bg-currentColor border border-error-800">
                <LockSimple size={22} className="text-error-800" />
              </Circle>
            ) : (
              <Circle className="bg-currentColor border border-primary-950">
                <LockSimpleOpen size={22} className="text-primary-950" />
              </Circle>
            )}
          </Button>
        );
      },
    }),
  ];

  return columns;
}
