import { QuestionMark } from "@phosphor-icons/react";
import { createColumnHelper } from "@tanstack/react-table";

import i18n from "@/i18n";
import type { Partner } from "@/types";
import { Circle } from "@/ui";

const columnHelper = createColumnHelper<Partner>();

export const partnersTableColumns = [
  columnHelper.display({
    id: "partnerName",
    header: i18n.t("partner.name"),
    cell: (info) => {
      const { logo, name } = info.row.original;
      return (
        <div className="flex items-center gap-x-2">
          {logo ? (
            <img className="size-9" src={logo ?? ""} alt="partner" />
          ) : (
            <Circle className="bg-white">
              <QuestionMark size={24} className="text-primary-600" />
            </Circle>
          )}
          <span>{name}</span>
        </div>
      );
    },
  }),
  columnHelper.accessor("contact", {
    header: i18n.t("partner.main_contact"),
    cell: (info) => <span>{info.getValue()} </span>,
  }),
  columnHelper.accessor("type", {
    header: i18n.t("partner.type_of_partner"),
    cell: (info) => <span className="capitalize">{info.getValue()} </span>,
  }),
];
