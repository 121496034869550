import type { ForwardedRef } from "react";
import React from "react";
import type { Control, FieldValues, Path, PathValue } from "react-hook-form";
import { useController } from "react-hook-form";

import { forwardRef, tw } from "@/utils";
import { arraysHaveSameElements } from "@/utils/array";
import { Label } from "./Label";
import { Message } from "./Message";
import { RadioButton } from "./RadioButton";

type RadioValue = string | number | boolean | string[];

interface RadioButtonGroupProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
  items: { label: string; value: RadioValue; default?: boolean }[];
  label?: React.ReactNode;
  error?: string;
  containerClassName?: string;
  className?: string;
  compact?: boolean;
  message?: string;
  required?: boolean;
}

export const RadioButtonGroup = forwardRef(
  <T extends FieldValues>(
    {
      name,
      control,
      items,
      label,
      error,
      containerClassName,
      className,
      compact,
      message,
      required = false,
    }: RadioButtonGroupProps<T>,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const {
      field: { onChange, value, ref: fieldRef },
    } = useController({
      name,
      control,
      defaultValue:
        (items.find((item) => item.default)?.value as PathValue<T, Path<T>>) ||
        (items[0]?.value as PathValue<T, Path<T>>),
    });

    function isChecked(itemValue: RadioValue): boolean {
      if (Array.isArray(value) && Array.isArray(itemValue)) {
        return arraysHaveSameElements(value, itemValue);
      }
      return value === itemValue;
    }

    return (
      <div
        className={tw(
          "flex flex-col gap-y-3 rounded-2xl border border-neutral-400 bg-neutral-100 p-4",
          containerClassName,
        )}
      >
        {!!label && (
          <div className="flex flex-row gap-x-1">
            <Label
              htmlFor={name}
              label={label}
              className="text-lg font-semibold text-neutral-900"
            />
            {required && (
              <span className="text-lg font-semibold text-error-500">*</span>
            )}
          </div>
        )}
        <div className={tw("flex flex-col gap-y-2", className)}>
          {items.map((item) => (
            <RadioButton
              key={item.value.toString()}
              id={`${name}-${item.value.toString()}`}
              label={item.label}
              value={item.value}
              checked={isChecked(item.value)}
              onChange={() => onChange(item.value)}
              error={error}
              ref={item.default ? fieldRef : ref}
            />
          ))}
        </div>
        {(!!compact || !!message || !!error) && (
          <Message message={message} error={error} />
        )}
      </div>
    );
  },
);
