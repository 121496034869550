import { zodResolver } from "@hookform/resolvers/zod";
import { EnvelopeSimpleOpen, Spinner, User } from "@phosphor-icons/react";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";

import { partnersQueryKeys, useCollaborators, useMedia } from "@/hooks";
import type { AddEditCollaborator, Collaborator } from "@/types";
import { collaboratorSchema, CollaboratorType } from "@/types";
import { Button, ControlledDropzone, errorToast, Input } from "@/ui";
import {
  bytesToMegabytes,
  getFileExtension,
  MAX_UPLOAD_SIZE_FOR,
} from "@/utils";

interface EditCollaboratorFormProps {
  onClose: () => void;
  partnerId?: string;
  initialCollaborator: Collaborator;
}

export const EditCollaboratorForm = ({
  onClose,
  initialCollaborator,
  partnerId,
}: EditCollaboratorFormProps) => {
  const { useUpload } = useMedia();
  const { upload, isUploading, fileHasUploaded } = useUpload();

  const { useUpdateCollaborator } = useCollaborators();
  const {
    mutateAsync: updateCollaborator,
    isPending: isPendingUpdateCollaboratorMutation,
  } = useUpdateCollaborator();

  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
  } = useForm<AddEditCollaborator>({
    resolver: zodResolver(collaboratorSchema),
    mode: "all",
    defaultValues: {
      name: initialCollaborator.name,
      title:
        initialCollaborator.title === "" || !initialCollaborator.title
          ? undefined
          : initialCollaborator.title,
      description: initialCollaborator.description,
      type: initialCollaborator.type,
      avatar: initialCollaborator?.avatar ?? undefined,
    },
  });

  const queryClient = useQueryClient();

  const invalidateAndClose = () => {
    if (partnerId) {
      void queryClient.invalidateQueries({
        queryKey: partnersQueryKeys.useSelfPacedCourse(partnerId),
      });
    }
    void queryClient.invalidateQueries({
      queryKey: ["collaborators", "getCollaborators"],
      exact: false,
    });
    onClose();
  };

  const onSubmit: SubmitHandler<AddEditCollaborator> = async (collaborator) => {
    const { avatar, ...collaboratorData } = collaborator;

    const avatarFile = avatar instanceof File ? avatar : null;

    if (avatarFile) {
      collaboratorData.extension = getFileExtension(avatarFile.name);
    }

    try {
      const collaborator = await updateCollaborator({
        collaboratorId: initialCollaborator.id,
        collaborator: {
          ...collaboratorData,
          avatar: avatarFile ?? avatar,
        },
      });
      if (avatarFile) {
        void upload(
          avatarFile,
          `/collaborators/${collaborator.id}/avatar/upload-url`,
        );
      }

      invalidateAndClose();
    } catch (error) {
      errorToast(error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-[540px] flex-col gap-y-8"
    >
      <div className="flex flex-col gap-y-8">
        <Input
          id="name"
          label={t("collaborators.name")}
          placeholder={t("collaborators.collaborator_name")}
          left={<User size={20} />}
          {...register("name")}
          error={errors.name?.message}
          required
        />

        <Input
          id="title"
          label={t("collaborators.organization")}
          placeholder={t("collaborators.collaborator_organization")}
          left={<EnvelopeSimpleOpen size={20} />}
          {...register("title")}
          error={errors.title?.message}
          required={initialCollaborator.type !== CollaboratorType.Teacher}
        />

        <Input
          id="description"
          label={t("collaborators.teachers.description")}
          placeholder={t("collaborators.teachers.teacher_description")}
          {...register("description")}
        />

        <ControlledDropzone
          name="avatar"
          control={control}
          loadedLabel={t("collaborators.avatar_uploaded")}
          label={t("collaborators.avatar_here")}
          placeholder={`${t("general.maximum_size")}: ${bytesToMegabytes(
            MAX_UPLOAD_SIZE_FOR.LOGO,
          )} ${t("general.mb")}`}
          error={errors.avatar?.message}
          state={
            fileHasUploaded ? "filled" : isUploading ? "loading" : "initial"
          }
          size="sm"
          fileUrl={initialCollaborator?.avatar}
        />
      </div>
      <div className="flex justify-center gap-2">
        <Button
          onClick={() => onClose()}
          variant="outlined"
          className="flex-grow"
        >
          {t("general.cancel")}
        </Button>

        <Button type="submit" className="flex-grow">
          {isPendingUpdateCollaboratorMutation ? (
            <Spinner className="h-5 w-5" />
          ) : (
            t("general.save_changes")
          )}
        </Button>
      </div>
    </form>
  );
};
