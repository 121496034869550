import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import type { SubmitHandler } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  meditationsQueryKeys,
  useBundles,
  useInterventionTypes,
  useMedia,
  useMeditations,
  usePracticeTypes,
  useThemes,
} from "@/hooks";
import { useCollaborators } from "@/hooks/useCollaborators";
import type {
  AddEditMeditation,
  Bundle,
  Collaborator,
  InterventionType,
  Meditation,
  Theme,
} from "@/types";
import { CollaboratorType, meditationSchema } from "@/types";
import type { PracticeType } from "@/types/models/practiceType";
import {
  Button,
  ControlledDropzone,
  errorToast,
  Heading,
  Input,
  Label,
  MultiSelect,
  RadioButtonGroup,
  SectionHeader,
  Select,
  Switch,
  TextArea,
  Toggle,
  useToastStore,
} from "@/ui";
import {
  bytesToMegabytes,
  convertToOptions,
  getFileExtension,
  MAX_UPLOAD_SIZE_FOR,
} from "@/utils";
import {
  convertMinutesToTimeFormat,
  convertTimeToMinutesFormat,
} from "@/utils/dates";
import { MeditationFormSection } from "./MeditationFormSection";

interface MeditationFormProps {
  initialData?: Meditation;
}

const toggleCourse = "Course";
const toggleTheme = "Theme";

export const MeditationForm = ({ initialData }: MeditationFormProps) => {
  const [selectedOption, setSelectedOption] = useState(
    initialData?.themes.length ? toggleTheme : toggleCourse,
  );

  const navigate = useNavigate();
  const pushToast = useToastStore((state) => state.pushToast);

  const handleToggle = (option: string) => {
    setSelectedOption(option);
    setValue("themes", []);
    setValue("bundles", []);
  };
  const { useAddMeditation, useUpdateMeditation } = useMeditations();
  const { useUpload } = useMedia();

  const { mutateAsync: addMeditation, isPending: isAddMeditationPending } =
    useAddMeditation();
  const {
    mutateAsync: updateMeditation,
    isPending: isUpdateMeditationPending,
  } = useUpdateMeditation();

  const { useAllInterventionTypes } = useInterventionTypes();
  const { data: interventionTypes } = useAllInterventionTypes();
  const interventionTypesOptions = convertToOptions<InterventionType>(
    interventionTypes?.data,
  );

  const { useAllPracticeTypes } = usePracticeTypes();
  const { data: practiceTypes } = useAllPracticeTypes();
  const practiceTypesOptions = convertToOptions<PracticeType>(
    practiceTypes?.data,
  );

  const { useAllCollaborators } = useCollaborators();
  const { data: collaborators } = useAllCollaborators({
    "filter[type]": CollaboratorType.Teacher,
  });
  const collaboratorsOptions = convertToOptions<Collaborator>(
    collaborators?.data,
  );

  const { useAllBundles } = useBundles();
  const { data: bundles } = useAllBundles();
  const bundlesOptions = convertToOptions<Bundle>(bundles?.data);

  const { useAllThemes } = useThemes();
  const { data: themes } = useAllThemes();
  const themesOptions = convertToOptions<Theme>(themes?.data);

  const radioItems = [
    { label: "Draft", value: false },
    { label: "Published", value: true },
  ];

  const complexityOptions = [
    { label: "Beginner", value: "beginner" },
    { label: "Intermediate", value: "intermediate" },
    { label: "Advanced", value: "advanced" },
  ];

  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    watch,
    setValue,
  } = useForm<AddEditMeditation>({
    resolver: zodResolver(meditationSchema),
    mode: "all",
    defaultValues: {
      name: initialData?.name ?? "",
      description: initialData?.description ?? "",
      free: initialData?.free ?? false,
      featured: initialData?.featured ?? false,
      complexity: initialData?.complexity ?? "beginner",
      intervention_type_id: initialData?.interventionType.id ?? "",
      practice_type_id: initialData?.practiceType.id ?? "",
      teacher_id: initialData?.teacher.id ?? "",
      duration: initialData
        ? convertTimeToMinutesFormat(initialData.duration).toString()
        : "0",
      language: initialData?.language ?? "",
      published: initialData?.published ?? false,
      bundles: initialData?.bundles.map((bundle) => bundle.id) ?? [],
      themes: initialData?.themes.map((theme) => theme.id) ?? [],
      audio: initialData?.audio ?? undefined,
      image: initialData?.image ?? undefined,
    },
    shouldFocusError: false,
  });

  const queryClient = useQueryClient();
  const invalidateAndRedirect = (isUpdate: boolean) => {
    void queryClient.invalidateQueries({
      queryKey: meditationsQueryKeys.useMeditation(initialData?.id),
      exact: false,
    });
    navigate("/meditations");
    void pushToast({
      type: "success",
      title: isUpdate
        ? t("meditations.updated_successfully_title")
        : t("meditations.created_successfully_title"),
      message: isUpdate
        ? t("meditations.updated_successfully_message")
        : t("meditations.created_successfully_message"),
    });
  };

  const {
    upload: uploadAudio,
    isUploading: isUploadingAudio,
    fileHasUploaded: audioHasUploaded,
  } = useUpload();
  const {
    upload: uploadImage,
    isUploading: isUploadingImage,
    fileHasUploaded: imageHasUploaded,
  } = useUpload();

  const isLoading =
    isUploadingAudio ||
    isUploadingImage ||
    isAddMeditationPending ||
    isUpdateMeditationPending;

  const uploadFiles = async (
    meditationId: string,
    audio?: File | null,
    image?: File | null,
  ) => {
    if (audio) {
      await uploadAudio(audio, `/meditations/${meditationId}/audio/upload-url`);
    }
    if (image) {
      await uploadImage(image, `/meditations/${meditationId}/image/upload-url`);
    }
  };

  const onSubmit: SubmitHandler<AddEditMeditation> = async (meditation) => {
    const { audio, image, ...meditationData } = meditation;
    meditationData.duration = convertMinutesToTimeFormat(
      Number(meditationData.duration),
    );

    const audioFile = audio instanceof File ? audio : null;
    const imageFile = image instanceof File ? image : null;

    if (audioFile) {
      meditationData.audio_extension = getFileExtension(audioFile.name);
    }
    if (imageFile) {
      meditationData.image_extension = getFileExtension(imageFile.name);
    }

    try {
      let finalMeditation;
      if (initialData) {
        finalMeditation = await updateMeditation({
          meditationId: initialData.id,
          meditation: {
            ...meditationData,
            audio: audioFile ?? audio,
            image: imageFile ?? image,
          },
        });
      } else {
        finalMeditation = await addMeditation({
          ...meditationData,
          audio,
          image,
        });
      }
      void uploadFiles(finalMeditation.id, audioFile, imageFile);
      invalidateAndRedirect(!!initialData);
    } catch (error) {
      errorToast(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-6">
      <SectionHeader
        left={
          <Heading
            level={3}
            heading={
              initialData
                ? t("meditations.edit_meditation")
                : t("meditations.add_new_meditation")
            }
          />
        }
        right={
          <div className="flex items-center space-x-2">
            <RadioButtonGroup
              name="published"
              control={control}
              items={radioItems}
              containerClassName="flex-row bg-tertiary-100 gap-x-3 border-none"
              className="flex-row gap-x-4"
            />
            <Button type="submit" size="lg" disabled={isLoading}>
              <span>
                {initialData
                  ? t("meditations.update_meditation")
                  : t("meditations.create_meditation")}
              </span>
            </Button>
          </div>
        }
      />

      <MeditationFormSection
        topLeft={<label>{t("meditations.main_details")}</label>}
        topRight={
          <div className="flex gap-x-2">
            <Switch name="free" control={control} />
            <p className="text-lg font-bold">
              {t("meditations.this_meditation_is_free")}
            </p>
          </div>
        }
      >
        <div className="flex w-full justify-between space-x-4">
          <Input
            id="title"
            label={t("meditations.title")}
            placeholder={t("meditations.meditation_title")}
            {...register("name")}
            error={errors.name?.message}
            required
            containerClassName="w-1/2"
          />
          <Select
            options={collaboratorsOptions}
            id="teacher"
            label={t("meditations.teacher")}
            placeholder={t("meditations.select_the_meditation_teacher")}
            {...register("teacher_id")}
            error={errors.teacher_id?.message}
            value={watch("teacher_id")}
            required
            containerClassName="w-1/2"
          />
        </div>
        <div className="flex w-full justify-between space-x-4">
          <Select
            options={complexityOptions}
            id="level"
            label={t("meditations.level")}
            placeholder={t("meditations.select_the_meditation_level")}
            {...register("complexity")}
            error={errors.complexity?.message}
            value={watch("complexity")}
            required
            containerClassName="w-1/2"
          />
          <Input
            id="language"
            label={t("meditations.language")}
            placeholder={t("meditations.select_language")}
            {...register("language")}
            error={errors.language?.message}
            required
            containerClassName="w-1/2"
          />
        </div>
        <TextArea
          id="description"
          label={t("meditations.description")}
          placeholder={t("meditations.add_the_full_meditation_description")}
          {...register("description")}
          error={errors.description?.message}
          required
        />
      </MeditationFormSection>

      <MeditationFormSection
        topLeft={<label>{t("meditations.choose_tags")}</label>}
      >
        <div className="flex w-full justify-between space-x-4">
          <Select
            options={practiceTypesOptions}
            id="practiceType"
            label={t("meditations.practice_type")}
            placeholder={t("meditations.select_the_practice_type")}
            {...register("practice_type_id")}
            error={errors.practice_type_id?.message}
            value={watch("practice_type_id")}
            required
            containerClassName="w-1/2"
          />
          <Select
            id="intervention_type"
            options={interventionTypesOptions}
            label={t("meditations.intervention_type")}
            {...register("intervention_type_id")}
            error={errors.intervention_type_id?.message}
            placeholder={t("meditations.select_the_intervention_type")}
            value={watch("intervention_type_id")}
            required
            containerClassName="w-1/2"
          />
        </div>
        <MeditationFormSection
          className="gap-y-6 rounded-2xl bg-tertiary-50"
          topLeft={
            <Toggle
              options={[toggleTheme, toggleCourse]}
              selectedOption={selectedOption}
              onToggle={handleToggle}
            />
          }
        >
          <div className="flex w-full justify-between space-x-4">
            {selectedOption === toggleCourse ? (
              <Controller
                name="bundles"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    id="bundles"
                    options={bundlesOptions}
                    label={t("meditations.course")}
                    error={errors.bundles?.message}
                    placeholder={t("meditations.select_the_course")}
                    value={value && value.length > 0 ? value[0] : ""}
                    onChange={(e) => {
                      onChange([e.target.value]);
                    }}
                    required
                    containerClassName="w-1/2"
                  />
                )}
              />
            ) : (
              <Controller
                control={control}
                name="themes"
                render={({ field: { onChange, value } }) => (
                  <MultiSelect
                    placeholder={t("meditations.select_the_themes")}
                    options={themesOptions}
                    selectedOptions={value ?? []}
                    onChange={onChange}
                    error={errors.themes?.message}
                    required
                    containerClassName="w-1/2"
                    label={t("meditations.themes")}
                  />
                )}
              />
            )}
            <div className="flex min-h-full w-1/2 items-center rounded-2xl bg-tertiary-100 p-6 leading-6">
              {selectedOption === toggleCourse
                ? t("meditations.meditation_will_only_appear_course")
                : t("meditations.meditation_will_only_appear_theme")}
            </div>
          </div>
        </MeditationFormSection>
      </MeditationFormSection>

      <MeditationFormSection
        topLeft={<label>{t("meditations.content")}</label>}
      >
        <div className="flex justify-between space-x-4">
          <ControlledDropzone
            name="image"
            control={control}
            loadedLabel={t("general.image_uploaded")}
            label={t("meditations.image_here")}
            placeholder={`${t("general.maximum_size")}: ${bytesToMegabytes(
              MAX_UPLOAD_SIZE_FOR.LOGO,
            )} ${t("general.mb")}`}
            error={errors.image?.message}
            state={
              imageHasUploaded
                ? "filled"
                : isUploadingImage
                  ? "loading"
                  : "initial"
            }
            containerClassName="w-1/4"
            fileUrl={initialData?.image}
          />

          <MeditationFormSection className="w-3/4 gap-y-6 rounded-2xl bg-tertiary-50">
            <div className="flex flex-row gap-x-1">
              <Label
                label={t("general.meditation")}
                className="text-lg font-semibold text-neutral-900"
              />

              <span className="text-lg font-semibold text-error-500">*</span>
            </div>
            <ControlledDropzone
              name="audio"
              control={control}
              loadedLabel={t("general.audio_uploaded")}
              label={t("meditations.audio_here")}
              placeholder={`${t("general.maximum_size")}: ${bytesToMegabytes(
                MAX_UPLOAD_SIZE_FOR.AUDIO,
              )} ${t("general.mb")}`}
              error={errors.audio?.message}
              state={
                audioHasUploaded
                  ? "filled"
                  : isUploadingAudio
                    ? "loading"
                    : "initial"
              }
              size="sm"
              fileUrl={initialData?.audio}
            />
            <Input
              id="duration"
              label={t("meditations.duration_minutes")}
              {...register("duration")}
              error={errors.duration?.message}
              type="number"
              required
              min="0"
            />
          </MeditationFormSection>
        </div>
      </MeditationFormSection>
    </form>
  );
};
