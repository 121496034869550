import { z } from "zod";

import i18n from "@/i18n";
import { ACCEPTED_IMAGE_TYPES, MAX_UPLOAD_SIZE_FOR } from "@/utils";
import { fileValidationRequired } from "@/utils/validations";

export interface Collaborator {
  id: string;
  name: string;
  description?: string;
  title: string;
  type: CollaboratorType;
  avatar?: string;
  activeMeditationsCount: number;
}

export enum CollaboratorType {
  Teacher = "teacher",
  Collaborator = "collaborator",
  Organizer = "organizer",
}

export const collaboratorSchema = z
  .object({
    name: z.string().min(1, i18n.t("collaborators.name_required")),
    title: z.string().optional(),
    description: z.string().optional(),
    avatar: z
      .union([
        z.string(),
        fileValidationRequired(
          MAX_UPLOAD_SIZE_FOR.LOGO,
          ACCEPTED_IMAGE_TYPES,
          i18n.t("collaborators.collaborator_photo_required"),
        ),
      ])
      .optional(),
    extension: z.string().nullable().optional(),
    type: z.enum(Object.values(CollaboratorType) as [CollaboratorType], {
      required_error: i18n.t("collaborators.type_required"),
    }),
  })
  .superRefine((data, ctx) => {
    if (data.type !== CollaboratorType.Teacher && !data.title) {
      ctx.addIssue({
        path: ["title"],
        message: i18n.t("collaborators.organization_required"),
        code: z.ZodIssueCode.custom,
      });
    }
  });

export type AddEditCollaborator = z.infer<typeof collaboratorSchema>;
