import { MagnifyingGlass, Plus, UsersThree } from "@phosphor-icons/react";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import { TeachersTable } from "@/components/Teachers/TeachersTable";
import { useSearch } from "@/hooks";
import { ContentLayout } from "@/layout";
import { MODAL_ROUTES, ROUTES } from "@/router";
import { useNavigateModal } from "@/router/useNavigateModal";
import { Breadcrumb, Button, Heading, SectionHeader } from "@/ui";
import { tw } from "@/utils";

export const Teachers = () => {
  const navigate = useNavigate();
  const navigateModal = useNavigateModal();
  const { searchQuery, handleSearchChange } = useSearch("");

  const breadcrumbItems = [
    {
      label: t("general.teachers"),
      href: ROUTES.teachers,
      icon: <UsersThree />,
    },
  ];

  return (
    <ContentLayout className="flex flex-col gap-y-6">
      <Breadcrumb
        items={breadcrumbItems}
        current={t("general.teachers")}
        onItemClick={(item) => {
          if (item.href) {
            navigate(item.href);
          }
        }}
      />
      <SectionHeader
        left={<Heading level={3} heading={t("general.teachers")} />}
      />
      <div className="flex items-center justify-between gap-x-2">
        <div className="relative flex flex-row items-center">
          <div className="pointer-events-none absolute pl-3">
            {
              <MagnifyingGlass
                size={20}
                className={tw(
                  "text-neutral-950",
                  !searchQuery && "text-neutral-500",
                )}
              />
            }
          </div>

          <input
            type="text"
            id="search"
            placeholder={t("users.search_placeholder")}
            value={searchQuery}
            onChange={handleSearchChange}
            className="block w-[456px] rounded-xl border border-neutral-400 p-4 pl-10 text-sm text-neutral-950 placeholder:text-neutral-400 hover:ring-1 hover:ring-tertiary-600 focus:shadow-input focus:outline-none focus:ring-1 focus:ring-tertiary-600"
          />
        </div>

        <Button
          size="md"
          onClick={() => navigateModal(MODAL_ROUTES.addTeacher)}
        >
          <Plus size={20} />
          {t("meditations.new_teacher")}
        </Button>
      </div>
      <TeachersTable searchQuery={searchQuery} />
    </ContentLayout>
  );
};
