import { useMutation, useQuery } from "@tanstack/react-query";

import { addCollaborator, getCollaborators, updateCollaborator } from "@/api";
import type { CollaboratorType } from "@/types";

export interface CollaboratorsFilter {
  "filter[name]"?: string;
  "filter[type]"?: CollaboratorType;
}

export const collaboratorsQueryKeys = {
  useCollaborators: (filters?: CollaboratorsFilter) => [
    "collaborators",
    "getCollaborators",
    filters,
  ],
};

export const useCollaborators = () => {
  const useAllCollaborators = (filters?: CollaboratorsFilter) =>
    useQuery({
      queryFn: () => getCollaborators(filters),
      queryKey: collaboratorsQueryKeys.useCollaborators(filters),
    });

  const useAddCollaborator = () =>
    useMutation({
      mutationFn: addCollaborator,
    });

  const useUpdateCollaborator = () =>
    useMutation({ mutationFn: updateCollaborator });

  return {
    useAllCollaborators,
    useAddCollaborator,
    useUpdateCollaborator,
  };
};
