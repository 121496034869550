import { t } from "i18next";
import { z } from "zod";

import i18n from "@/i18n";
import { bytesToMegabytes } from "./media";

const domainValidation = z
  .string()
  .regex(/^@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/, {
    message: i18n.t("partner.domain_format_error"),
  })
  .optional();

// Reusable file validation
const fileValidation = (maxUploadSize: number, allowedTypes: string[]) =>
  z
    .instanceof(File)
    .optional()
    .nullable()
    .refine(
      (file) => {
        return !file || file.size <= maxUploadSize;
      },
      `${i18n.t("error.file_size_must_be_less")} ${bytesToMegabytes(maxUploadSize)}MB`,
    )
    .refine(
      (file) => {
        return !file?.type || allowedTypes.includes(file.type);
      },
      `${t("error.file_must_be_types")} ${allowedTypes.join(", ")}`,
    );

const fileValidationRequired = (
  maxUploadSize: number,
  allowedTypes: string[],
  requiredMessage: string,
) =>
  z
    .any()
    .refine((file) => !!file, { message: requiredMessage })
    .refine((file) => file instanceof File, { message: "Invalid file input" })
    .refine((file) => file?.size <= maxUploadSize, {
      message: `${t("error.file_size_must_be_less")} ${bytesToMegabytes(maxUploadSize)}MB`,
    })
    .refine((file) => allowedTypes.includes(file?.type || ""), {
      message: `${t("error.file_must_be_types")} ${allowedTypes.join(", ")}`,
    });

export { domainValidation, fileValidation, fileValidationRequired };
